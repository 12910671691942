$(document).ready(function() {
    $('.sidemenu-link').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('sidemenu-link-active');
        $('.sidemenu').toggleClass('sidemenu-active');
    });
    

    function hideSidemenu() {
        $('.sidemenu').removeClass('sidemenu-active');
        $('.sidemenu-link').removeClass('sidemenu-link-active');
    }
    
    // Hide the .sidemenu when the user taps outside of it or scrolls the page 
    // (for mobile devices)
    $(document.body).on('touchstart', function(event) {
        if (!$(event.target).closest('.sidemenu').length) {
            hideSidemenu();
        }
    });
    $(window).scroll(function(event) {
        hideSidemenu();
    });
});